<template>
  <div
    class="container"
    v-loading="loading">
    <div class="title">{{ contents.title }}</div>
    <div class="time">
      {{ contents.createTime }}
    </div>
    <div class="contents">
      <div class="describe">{{ contents.describes }}</div>
      <div
        class="content"
        v-html="contents.content"></div>
    </div>
  </div>
</template>

<script>
import { getContentDetail } from '@/api/8.30';
export default {
  name: 'contents',
  data: () => {
    return {
      contents: '',

      loading: false,
    };
  },
  created() {
    this.getContent(this.$route.query.from);
    let forms = parseInt(this.$route.query.from);
  },
  methods: {
    //获取内容详情
    async getContent(type) {
      this.loading = true;
      // //学校党建内容其他接口获取数据
      const { data } = await getContentDetail({
        id: this.$route.query.courseId,
      });
      this.contents = data;
      console.log(data);
      // if(type==5){
      //   const { data } = await getPartyDetail({ id: this.$route.query.cid });
      //   this.contents = data;
      // }else{
      //   const { data } = await getDetail({ id: this.$route.query.cid });
      //   this.contents = data;
      // }

      this.loading = false;
    },
    checkFile(fileUrl) {
      // 取出文件后缀名
      if (fileUrl) {
        let suffixArr = fileUrl.split('.');
        let fileSuffix = suffixArr[suffixArr.length - 1];

        const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
        if (imgList.indexOf(fileSuffix) > -1) {
          return 'img';
        } else {
          console.log(fileSuffix);
        }

        const videoList = [
          'mp4',
          'rmvb',
          'avi',
          'flv',
          'm2v',
          'mkv',
          'wmv',
          'mp3',
          'wav',
        ];
        if (videoList.indexOf(fileSuffix) > -1) {
          return 'video';
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: PingFang-SC;
  src: url('../../assets/fonts/苹方黑体-中粗-简.ttf');
}
.container {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  // margin: 20px auto;
  .title {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
    font-size: PingFang-SC;
    padding-top: 10px;
  }
  .time {
    text-align: center;
    padding: 10px;
    font-size: PingFang SC;
    font-size: 15px;
    opacity: 0.5;
  }
  .contents {
    padding-bottom: 20px;
    .content {
      //font-size: 15px;
      //line-height: 18px;
      font-size: PingFang SC;
      opacity: 0.8;
      word-wrap: break-word;
      white-space: pre-wrap;
      //text-align:center;
    }
    .describe {
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      margin: 10px auto;
    }
    .media {
      text-align: center;
    }
  }
}
</style>
